<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="shadowCard">
    	<v-card-title primary-title>
    	  Actividades
    	</v-card-title>
    	<v-card-text class="black--text">

    		<v-row>
    			<v-col cols="12" lg="4" md="6">
		    		<label>Elige una semana</label>
		    		<v-radio-group
				      v-model="semana"
				      row
				    >
				      <v-radio
				        label="Semana 1"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Semana 2"
				        :value="2"
				      ></v-radio>
              <v-radio
				        label="Semana 3"
				        :value="3"
				      ></v-radio>
				      <v-radio
				        label="Semana 4"
				        :value="4"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>

	      <v-row dense>
          <v-col cols="12">
            <component v-if="semana" :is="componenteSemana" />
          </v-col>
        </v-row>
    	</v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
	import axios from 'axios';

  import { mapGetters, mapActions }    from "vuex";

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import SemanaUno             from '@/components/SemanaUno.vue';
  import SemanaDos             from '@/components/SemanaDos.vue';
  import SemanaTres             from '@/components/SemanaTres.vue';
  import SemanaCuatro             from '@/components/SemanaCuatro.vue';

  export default {

  	components:{
      Alerta,
      carga,
      SemanaUno,
      SemanaDos,
      SemanaTres,
      SemanaCuatro,
    },

    mixins: [ validarErrores ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	edad: null,
    	modalidad: null,
    	horario: null,
    	hermanos: null,

    	promociones: [],
    	semana: null,


    }),

    created( ){

    	this.initialize( )
    },

    methods:{

    	initialize( ){
				this.cargar = true;
	      this.promociones = [];
	      this.$http.get("promociones.imagenes").then((response) => {
	        this.promociones = response.data
	        console.log( this.promociones )
	        this.cargar = false;
	      }).catch((error) => {
	        this.validarError(error);
	      }).finally(() => {
	        this.cargar = false;
	      });
    	}

    },

    computed:{
      ...mapGetters('login',['getEscuela','getdatosUsuario']),

    	url( ){

    		let nombreArchivo = this.semana ? this.semana ==  2 ? 'calendario_fast_clases.jpeg' : 'calendario_inbi_clases.jpeg' : this.getsemana == 2 ? 'calendario_fast_clases.jpeg' : 'calendario_inbi_clases.jpeg'
    	   this.semana = this.semana ? this.semana ==  2 ? 2 : 1 : this.getsemana == 2 ? 2 : 1

    		let link = 'https://semanakpi.club/kpi/imagenes-promociones/' + nombreArchivo

        return link

    	},

      componenteSemana() {
        const componentes = {
          1: 'SemanaUno',
          2: 'SemanaDos',
          3: 'SemanaTres',
          4: 'SemanaCuatro',
        };
        return componentes[this.semana] || null;
      } ,
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}

</style>
